import { makeStyles } from '@mui/styles';
import { deepPurple } from '@mui/material/colors';

export default makeStyles(theme => ({
    appBar: {
        flexDirection: 'column',
        borderRadius: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0, 4),
        
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(1, 2),
        },
    
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1, 3),
        },
    
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            padding: theme.spacing(1, 4),
        },
    
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(1, 5),
        },
    
        [theme.breakpoints.up('xl')]: {
            padding: theme.spacing(1, 6),
        },
        
        '@media print': {
            display: 'none !important',
        },
    },
    
    heading: {
        textDecoration: 'none',
        fontWeight: 300,
        
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem !important',
        },
    },
    
    image: {
        marginLeft: '10px',
        marginTop: '5px',
    },
    
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    
    profile: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        maxWidth: 400,
        alignItems: 'center',
    },
    
    logout: {
        marginLeft: '20px',
    },
    
    userName: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    },
    
    brandContainer: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
}));
