import axios from 'axios';

import { API_URL } from '../constants';

const API = axios.create({ baseURL: API_URL + ':5002/estates' });

API.interceptors.request.use(req => {
    const user = localStorage.getItem('profile');
    
    if (user) {
        req.headers.authorization = `Bearer ${JSON.parse(user).token}`;
    }
    
    return req;
});

export const fetchEstates          = (page, limit) => API.get(`?page=${page}&limit=${limit}`);
export const fetchEstate           = id => API.get(`/${id}`);
export const createEstate          = newEstate => API.post('', newEstate);
export const updateEstate          = (id, updatedPost) => API.patch(`/${id}`, updatedPost);
export const deleteEstate          = id => API.delete(`/${id}`);

export const fetchEstatesBySearch = (searchQuery, columnQuery, page, limit) => {
    const search = searchQuery || '';
    const column = columnQuery || '';

    return API.get(`/search?searchQuery=${search}&columnQuery=${column}&page=${page}&limit=${limit}`);
}

export const importEstates = formData => API.post('/import', formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const fetchEstateFieldNames = () => API.get(`/fieldNames`);
