export const FETCH_ALL         = 'FETCH_ALL';
export const FETCH_BY_SEARCH   = 'FETCH_BY_SEARCH';
export const FETCH_ONE         = 'FETCH_ONE';
export const FETCH_FIELD_NAMES = 'FETCH_FIELD_NAMES';
export const CREATE            = 'CREATE';
export const UPDATE            = 'UPDATE';
export const DELETE            = 'DELETE';
export const IMPORT            = 'IMPORT';
export const START_LOADING     = 'START_LOADING';
export const END_LOADING       = 'END_LOADING';
