import React from 'react';
import moment from 'moment';

import { TextField, Grid } from '@mui/material';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { DATES, FIELDS, NUMBERS } from '../../constants/components/EstateForm';

const Input = ({ name, label, estateData, handleChange }) => {
    label = label || FIELDS[name] || name;
    
    const value    = estateData[name] || '';
    const textarea = name === 'megjegyzes';
    
    const renderInput = () => {
        if (DATES.includes(name)) {
            return (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        name={name}
                        label={label}
                        value={value || null}
                        views={['year', 'month', 'day']}
                        openTo="year"
                        placeholder="yyyy.mm.dd."
                        onChange={newValue => handleChange({
                            target: {
                                name: name,
                                value: moment(newValue).format('YYYY.MM.DD.') || null,
                            },
                        })}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </LocalizationProvider>
            );
        }
        
        const isNumber   = NUMBERS.includes(name);
        const type       = isNumber ? 'number' : 'text';
        const minAndStep = isNumber ? 1 : null;
        
        return (
            <TextField
                name={name}
                variant="outlined"
                type={type}
                label={label}
                fullWidth
                value={value}
                onChange={handleChange}
                inputProps={{ min: minAndStep, step: minAndStep }}
                multiline={textarea}
                minRows={textarea ? 4 : 1}
            />
        );
    }
    
    return textarea ? (
        <Grid item name={name} xs={12} lg={10} xl={9} style={{ marginTop: 24 }}>{renderInput(name)}</Grid>
    ) : (
        <Grid item name={name} xs={12} sm={6} md={4} lg={3} xl={2}>{renderInput(name)}</Grid>
    );
}

export default Input;
