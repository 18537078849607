import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    container: {},

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',

        '& > .dropzone > div': {
            padding: theme.spacing(6) + ' !important',
            border: '2px dashed #cccccc',
            backgroundColor: '#eeeeee',
        }
    },

    acceptedState: {
        borderColor: '#5CFF5C !important',
        backgroundColor: 'rgba(92, 255, 92, 0.4) !important',
    },

    rejectedState: {
        borderColor: '#ff0000 !important',
        backgroundColor: 'rgba(255, 0, 0, 0.4) !important',
    },
}));
