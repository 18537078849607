export const apiUrl = () => {
    const origin   = window.location.origin;
    const position = origin.lastIndexOf(':');
    
    return position < 7 ? origin : origin.substring(0, position);
}

export const isString        = str  => typeof str === 'string';
export const isStringEmpty   = str  => isString(str) && str.length === 0;
export const isNumber        = num  => typeof num === 'number' && !isNaN(num);
export const isZero          = num  => isNumber(num) && num === 0;
export const isOne           = num  => isNumber(num) && num === 1;
export const isValidNumberId = id   => isNumber(id)  && id > 0;
export const isBoolean       = bool => typeof bool === 'boolean';
export const isArray         = arr  => Array.isArray(arr);
export const isArrayEmpty    = arr  => !isArray(arr) || arr.length === 0;
export const isFunction      = func => typeof func === 'function';
export const isObject        = obj  => !!obj && Object.prototype.toString.call(obj) === '[object Object]';
export const isObjectEmpty   = obj  => !isObject(obj) || isArrayEmpty(Object.keys(obj));

export const fixObject       = obj  => isObject(obj) ? obj : {};
export const objectToEncoded = obj  => Object.keys(obj).map(
    k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
).join('&');
