import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Pagination, PaginationItem } from '@mui/material';

import { getEstates, getEstatesBySearch } from '../../actions/estates';

import useStyles from './styles';

const Paginate = ({ page, search, column }) => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const limit    = 12;
    
    const { numberOfPages } = useSelector(state => state.estates);
    
    useEffect(() => {
        if (page || limit) {
            if (search.trim() || column.trim()) {
                dispatch(getEstatesBySearch(String(search), String(column), Number(page), Number(limit)));
            } else {
                dispatch(getEstates(Number(page), Number(limit)));
            }
        }
    }, [page, search, column, limit, dispatch]);
    
    return (
        <Pagination
            classes={{ ul: classes.ul }}
            count={numberOfPages}
            page={Number(page) || 1}
            variant="outlined"
            color="primary"
            showFirstButton
            showLastButton
            renderItem={item => (
                <PaginationItem
                    component={Link}
                    to={`?page=${item.page}&searchQuery=${search}&columnQuery=${column}`}
                    { ...item }
                />
            )}
        />
    );
}

export default Paginate;
