import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    
    button: {
        '& span': {
            marginLeft: 8
        }
    },
    
    submit: {
        margin: theme.spacing(3, 0, 2, 2) + ' !important',
    },
    
    googleButton: {
        margin: theme.spacing(2),
    },
}));
