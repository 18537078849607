import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    container: {
        minHeight: '100%',
        
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2) + ' !important',
        },
    }
}));