import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
   root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
        },
    },
    
    heading: {
        margin: theme.spacing(2, 0, 4) + ' !important',
        
        [theme.breakpoints.down('sm')]: {
            fontSize: '2em !important',
        },
    },
    
    paper: {
        padding: theme.spacing(2),
    },
    
    form: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    
    button: {
        margin: "32px 16px 16px 16px !important",
        
        '& span': {
            marginLeft: 8,
        },
    },
    
    close: {
        position: 'absolute !important',
        top: -16,
        right: -16,
    },
}));
