import React, { useState } from 'react';

import { useSelector } from "react-redux";

import {
    Grid,
    CircularProgress,
    Paper,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@mui/material";

import DeleteIcon  from '@mui/icons-material/Delete';

import Estate from "./Estate/Estate";

import useStyles from './styles';

const Estates = ({ setCurrentId, deleteEstate }) => {
    const { estates, isLoading, fieldNames }      = useSelector(state => state.estates);
    const [deleteData, setDeleteData] = useState({ open: false, callback: null });
    
    const classes = useStyles();
    
    const handleDeleteOpen   = callback => {
        setDeleteData({ ...deleteData, open: true, callback });
    };
    
    const handleDeleteClose = () => setDeleteData({ ...deleteData, open: false });
    
    const handleDeleteAgree = () => {
        if (typeof deleteData?.callback === 'function') {
            deleteData.callback();
        }
        
        setDeleteData({ ...deleteData, open: false, callback: null });
    }
    
    const template = (
        <Grid container justifyContent="stretch" alignItems="stretch" spacing={3} className={classes.container}>
            {!estates.length && !isLoading ? (
                <Grid item xs={12}>
                    <Paper className={classes.paper} elevation={3}>
                        <Typography variant="h4" color="error">Nem található egy cím sem.</Typography>
                    </Paper>
                </Grid>
            ) : estates.map(estate => (
                <Grid item key={estate._id} xs={12} md={6} lg={4}>
                    <Estate
                        fieldNames={fieldNames}
                        estate={estate}
                        setCurrentId={setCurrentId}
                        deleteEstate={deleteEstate}
                        handleDeleteOpen={handleDeleteOpen}
                    />
                </Grid>
            ))}
    
            <Dialog
                open={!!deleteData?.open}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Biztos, hogy törölni akarod a házat?</DialogTitle>
                
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ennek elfogadásával végleg törlöd a rendszerből a kiválasztott házat.
                    </DialogContentText>
                </DialogContent>
                
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">Mégse</Button>
                    <Button onClick={handleDeleteAgree} autoFocus color="error" variant="contained">
                        <DeleteIcon fontSize="small"/>
                        <span style={{ marginLeft: 4 }}>Törlés</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
    
    return isLoading ? <CircularProgress /> : template;
}

export default Estates;
