import React, { useEffect } from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import moment from 'moment';

import { useDispatch, useSelector } from "react-redux";

import { Grid, Paper, Typography, CircularProgress, Divider, Button, useMediaQuery, Box } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';

import EstateForm from '../EstateForm/EstateForm';

import { getEstate } from '../../actions/estates';

import useStyles from './styles';
import ViewIcon from '@mui/icons-material/Visibility';
import HideIcon from '@mui/icons-material/VisibilityOff';

const useQuery = () => new URLSearchParams(useLocation().search);

const Estate = function() {
    const { estate, isLoading, fieldNames } = useSelector(state => state?.estates);

    const { id }     = useParams();
    const query      = useQuery();
    const classes    = useStyles();
    const dispatch   = useDispatch();
    const navigate   = useNavigate();
    const theme      = useTheme();
    
    const all        = Number(query.get('all') || localStorage.getItem('showEstateEmptyFields')) || 0;
    const allText    = `Üres mezők ${!!all ? 'rejtése' : 'mutatása'}`;
    
    const buttonSize = useMediaQuery(theme.breakpoints.down('md')) ? 'large' : 'small';
    
    const openEdit = () => {
        document.getElementById('EstateForm')?.classList?.add('open');
    }
    
    const toggleEmptyFields = () => {
        const switchAll = !!all ? 0 : 1;
        localStorage.setItem('showEstateEmptyFields', String(switchAll));
        navigate(`?all=${switchAll}`);
    }

    useEffect(() => {
        dispatch(getEstate(id));
    }, [id, dispatch]);
    
    return estate ? (
        <Paper className={classes.paper} elevation={6}>
            <div className={classes.card}>
                {isLoading ? <CircularProgress /> : (
                    <div className={classes.section}>
                        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="stretch">
                            <Typography className={classes.title} variant="h3" component="h2" displayPrint="none">
                                {estate[fieldNames[0]]}
                            </Typography>
            
                            <Box displayPrint="none !important">
                                <Button
                                    className={classes.button}
                                    size={buttonSize}
                                    color="success"
                                    title={allText}
                                    onClick={toggleEmptyFields}
                                >
                                    {!!all ? <HideIcon fontSize={buttonSize} /> : <ViewIcon fontSize={buttonSize} />}
                                    <span>{allText}</span>
                                </Button>
                                
                                <Button
                                    className={classes.button}
                                    size={buttonSize}
                                    color="primary"
                                    title="Nyomtatás"
                                    onClick={window.print}
                                >
                                    <PrintIcon fontSize={buttonSize} />
                                    <span>Nyomtatás</span>
                                </Button>
                
                                {/*<Button
                                    className={classes.button}
                                    size={buttonSize}
                                    color="warning"
                                    title="Szerkesztés"
                                    onClick={openEdit}
                                >
                                    <EditIcon fontSize={buttonSize} />
                                    <span>Szerkesztés</span>
                                </Button>*/}
                            </Box>
                        </Box>
        
                        <Divider style={{ margin: '20px 0' }} className="hideOnPrint" />
        
                        <div className={classes.dates}>
                            <Typography variant="body1">Utoljára módosítva: {moment(estate?.lastModified).fromNow()}</Typography>
                            <Typography variant="body1">Létrehozva: {moment(estate?.createdAt).fromNow()}</Typography>
                        </div>
        
                        <Divider style={{ margin: '20px 0' }} className="hideOnPrint" />
    
                        <Grid container className={classes.gridContainer}>
                            {fieldNames.map(value => {
                                let estateFieldValue = estate ? estate[value] : '';

                                if (estateFieldValue === 'undefined') {
                                    estateFieldValue = '';
                                }

                                if (isNaN(Number(estateFieldValue)) && !isNaN(new Date(estateFieldValue).getDate())) {
                                    estateFieldValue = moment(estateFieldValue).format('YYYY. MMMM D.')
                                }

                                if (value === 'Megjegyzés') {
                                    return !!all || estateFieldValue ? (
                                        <Grid item key={value} xs={12} className={classes.field + ' megjegyzes'}>
                                            <Typography variant="body1" fontWeight="bold">
                                                {decodeURIComponent(value.replace(/__/gi, '.'))}:
                                            </Typography>
                                            <Typography variant="body1" color={estateFieldValue ? '' : 'error'}>
                                                {estateFieldValue || 'Nincs adat.'}
                                            </Typography>
                                        </Grid>
                                    ) : '';
                                }
                                
                                return !!all || estateFieldValue ? (
                                    <Grid item key={value} xs={12} sm={6} md={4} lg={3} className={classes.field}>
                                        <Typography variant="body1" fontWeight="bold">
                                            {decodeURIComponent(value.replace(/__/gi, '.'))}:
                                        </Typography>
                                        <Typography variant="body1" color={estateFieldValue ? '' : 'error'}>
                                            {estateFieldValue || 'Nincs adat.'}
                                        </Typography>
                                    </Grid>
                                ) : '';
                            })}
                        </Grid>
    
                        <div id='EstateForm' className={classes.form}>
                            <EstateForm defaultEstate={estate} />
                        </div>
                    </div>
                )}
            </div>
        </Paper>
    ) : null;
}

export default Estate;
