import React from 'react';

import Input from './Input';
import { FIELDS } from '../../constants/components/EstateForm';

import { Grid } from '@mui/material';

const Inputs = ({ estateData, handleChange }) => {
    return (
        <Grid container spacing={2} justifyContent="center">
            {Object.entries(FIELDS).map(([name, label]) => (
                <Input
                    key={name}
                    name={name}
                    label={label}
                    estateData={estateData}
                    handleChange={handleChange}
                />
            ))}
        </Grid>
    );
}

export default Inputs;
