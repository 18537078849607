import * as api from '../api/estates';

import {
    FETCH_ALL,
    FETCH_BY_SEARCH,
    FETCH_ONE,
    CREATE,
    UPDATE,
    DELETE,
    START_LOADING,
    END_LOADING,
    IMPORT, FETCH_FIELD_NAMES,
} from '../constants/action/estate';

export const getEstates = (page, limit) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        
        const { data } = await api.fetchEstates(page, limit);
        
        dispatch({
            type: FETCH_ALL,
            payload: data || []
        });
        
        dispatch({ type: END_LOADING });
    } catch (err) {
        console.log(err);
    }
}

export const getEstatesBySearch = (searchQuery, columnQuery, page, limit) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        
        const { data } = await api.fetchEstatesBySearch(searchQuery, columnQuery, page, limit);
        
        dispatch({
            type: FETCH_BY_SEARCH,
            payload: data || []
        });
    
        dispatch({ type: END_LOADING });
    } catch (err) {
        console.log(err);
    }
}

export const getEstate = id => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        
        const { data } = await api.fetchEstate(id);
        
        dispatch({
            type: FETCH_ONE,
            payload: data || []
        });
    
        dispatch({ type: END_LOADING });

        return data;
    } catch (err) {
        console.log(err);
    }
}

export const createEstate = estate => async (dispatch) => {
    try {
        const { data } = await api.createEstate(estate);
        
        dispatch({
            type: CREATE,
            payload: data || []
        });
    } catch (err) {
        console.log(err);
    }
}

export const updateEstate = (id, estate) => async (dispatch) => {
    try {
        const { data } = await api.updateEstate(id, estate);
        
        dispatch({
            type: UPDATE,
            payload: data || []
        });
    } catch (err) {
        console.log(err);
    }
}

export const deleteEstate = id => async (dispatch) => {
    try {
        await api.deleteEstate(id);
        
        dispatch({
            type: DELETE,
            payload: id || 0
        });
    } catch (err) {
        console.log(err);
    }
}

export const importEstates = formData => async (dispatch) => {
    try {
        const success = await api.importEstates(formData);

        dispatch({
            type: IMPORT,
            payload: success || null
        });
    } catch (err) {
        console.log(err);
    }
}

export const getEstateFieldNames = () => async (dispatch) => {
    try {
        const { data } = await api.fetchEstateFieldNames();
        const payload = data?.estateFieldNames || [];

        dispatch({
            type: FETCH_FIELD_NAMES,
            payload
        });

        return payload;
    } catch (err) {
        console.log(err);
    }
}
