import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    container: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2) + ' !important',
        },
    
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 1) + ' !important',
        },
    },
    
    form: {
        position: 'fixed',
        zIndex: -1,
        top: 0,
        left: 0,
        display: 'none',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        padding: 20,
        boxSizing: 'border-box',
        background: 'rgba(0, 0, 0, 0.75)',
        overflow: 'auto',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        
        '&.open': {
            zIndex: 1,
            display: 'flex',
            opacity: 1,
        }
    },
    
    paper: {
        padding: 2,
        paddingBottom: '156px !important',
    
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
    },
    
    actions: {
        position: 'fixed',
        zIndex: 0,
        bottom: 32,
        left: '50%',
        transform: 'translateX(-50%)',
        opacity: 1,
        transition: 'opacity 0.3s ease',
        padding: 16,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        
        '&.open': {
            opacity: 0,
        },
    },
    
    button: {
        margin: '0 auto !important',

        '& > span': {
            margin: '5px 7px 0 10px',
        },

        '& + *': {
            marginTop: theme.spacing(2) + ' !important',
        },

        [theme.breakpoints.up('sm')]: {
            '& + *': {
                marginTop: '0 !important',
                marginLeft: theme.spacing(2) + ' !important',
            }
        },
    },
    
    appBarSearch: {
        borderRadius: 4,
        marginBottom: '1rem',
        display: 'flex',
        padding: '16px',

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row !important',
        },
    },

    appBarSearchField: {
        flex: "1 1 100%",

        '& + *': {
            marginTop: theme.spacing(1) + ' !important',
        },

        [theme.breakpoints.up('sm')]: {
            flex: "1 1 50%",

            '& + *': {
                margin: '0 !important',
            },

            '&:nth-child(2n)': {
                marginLeft: theme.spacing(1) + ' !important',
            },

            '&:nth-child(2n+1)': {
                marginRight: theme.spacing(1) + ' !important',
            },
        },
    },
    
    pagination: {
        borderRadius: 4,
        marginTop: '1rem',
        padding: '16px',
    },
}));
