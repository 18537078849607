import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import {
    Container,
    Grid,
    Grow,
    Fab,
    Paper,
    Typography,
    AppBar,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon  from '@mui/icons-material/Edit';
import { getEstateFieldNames } from '../../actions/estates';

import Estates from '../Estates/Estates';
import EstateForm from '../EstateForm/EstateForm';
import Paginate from '../Paginate/Paginate';

import useStyles from './styles';
import { CloudUpload } from "@mui/icons-material";

const useQuery = () => new URLSearchParams(useLocation().search);

const Home = () => {
    const [fieldNames, setFieldNames] = useState([]);

    const classes       = useStyles();
    const user          = JSON.parse(localStorage.getItem('profile'));
    const navigate      = useNavigate();
    const query         = useQuery();
    const page          = Number(query.get('page')) || 1;
    const dispatch      = useDispatch();
    const defaultSearch = '';
    const searchQuery   = query.get('searchQuery') || defaultSearch || '';
    const columnQuery   = query.get('columnQuery') || fieldNames[0] || '';
    const limit         = 12;

    const [currentId, setCurrentId] = useState(null);
    const [search, setSearch]       = useState(searchQuery.trim());
    const [column, setColumn]       = useState(columnQuery.trim());

    useEffect( () => {
        dispatch(getEstateFieldNames()).then(r => {
            setFieldNames(r);
            setColumn(r[0]);
        });
    }, [dispatch]);

    const handleOpenButton = () => {
        document.getElementById('EstateForm').classList.toggle('open');
        document.getElementById('EstateFormOpenButton').classList.toggle('open');
    }
    
    useEffect(() => {
        const s = search.trim() || '';
        const c = fieldNames.includes(column) ? column.trim() : fieldNames[0];

        navigate(`?page=1&searchQuery=${s}&columnQuery=${c}`);
    }, [limit, navigate, search, column, fieldNames]);


    return (
        <Grow in>
            <Container maxWidth="xl" className={classes.container}>
                {
                    user ? (
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="stretch"
                            spacing={3}
                            className={classes.paper}
                        >
                            <Grid item xs={12}>
                                <AppBar
                                    className={classes.appBarSearch}
                                    position="static"
                                    color="inherit"
                                >
                                    <TextField
                                        className={classes.appBarSearchField}
                                        autoFocus
                                        name="search"
                                        label="Keresés..."
                                        value={search}
                                        variant="outlined"
                                        onChange={e => setSearch(e.target.value)}
                                    />

                                    <FormControl className={classes.appBarSearchField} variant="outlined">
                                        <InputLabel id="columnLabel">Mező</InputLabel>

                                        <Select
                                            name="column"
                                            label="Mező"
                                            labelId="columnLabel"
                                            value={column}
                                            variant="outlined"
                                            onChange={e => setColumn(e.target.value)}
                                        >
                                            {fieldNames.map(label => (
                                                <MenuItem
                                                    value={label}
                                                    key={label}
                                                    selected={label === column}
                                                >
                                                    {decodeURIComponent(label.replace(/__/gi, '.'))}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </AppBar>
                            </Grid>
    
                            <Grid item xs={12}>
                                <Paper className={classes.pagination} elevation={6}>
                                    <Paginate page={Number(page)} search={String(search)} column={String(column)} />
                                </Paper>
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Estates setCurrentId={setCurrentId} />
                            </Grid>
                            
                            <Grid item xs={12}>
                                <Paper className={classes.pagination} elevation={6}>
                                    <Paginate page={Number(page)} search={String(search)} column={String(column)} />
                                </Paper>
                            </Grid>
        
                            <div id='EstateForm' className={classes.form}>
                                <EstateForm currentId={currentId} setCurrentId={setCurrentId} />
                            </div>
                            
                            <Paper elevation={24} className={classes?.actions}>
                                {/*<Fab
                                    id='EstateFormOpenButton'
                                    color="primary"
                                    variant="extended"
                                    className={classes.button}
                                    aria-label={currentId ? 'add' : 'edit'}
                                    onClick={handleOpenButton}
                                >
                                    { !currentId ? <AddIcon /> : <EditIcon /> }
                                    <span>{ !currentId ? 'Új cím' : 'Cím szerkesztése' }</span>
                                </Fab>*/}

                                <Fab
                                    color="primary"
                                    variant="extended"
                                    className={classes.button}
                                    aria-label="Frissítés"
                                    onClick={() => navigate('/import')}
                                >
                                    <CloudUpload />
                                    <span>Frissítés</span>
                                </Fab>
                            </Paper>
                        </Grid>
                    ) : (
                        <Paper className={classes.paper}>
                            <Typography variant="h6" align="center">
                                Jelentkezz be a Nyilvántartás használatához!
                            </Typography>
                        </Paper>
                    )
                }
            </Container>
        </Grow>
    );
}

export default Home;
