import React, {useCallback} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';

import { Button, Paper } from '@mui/material';
import { CloudUpload } from "@mui/icons-material";

import useStyles from './styles';

import { importEstates } from '../../actions/estates';

function Import() {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const dropzone = useDropzone({
        maxFiles: 1,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/vnd.ms-excel': [],
            'text/csv': [],
        }
    });

    const { getRootProps, getInputProps } = dropzone;

    const importFile = useCallback(async file => {
        const formData = new FormData();

        formData.append('estates', file, 'estates');

        await dispatch(importEstates(formData));
        navigate('/');
    }, []);

    const dropzoneState = dropzone => {
        if (dropzone.isDragAccept) {
            return {
                className: classes.acceptedState,
                label: 'A fájl megfelelő típusú!',
            }
        }

        if (dropzone.isDragReject) {
            return {
                className: classes.rejectedState,
                label: 'A fájl nem megfelelő típusú!',
            }
        }

        if (dropzone.acceptedFiles.length) {
            return {
                className: classes.acceptedState,
                label: `Fájl: ${dropzone.acceptedFiles[0].name}`,
            }
        }

        return {
            className: classes.defaultState,
            label: 'Kattints vagy húzd be a fájlt ide!',
        }
    };

    return (
        <Paper className={classes.paper} elevation={3} spacing={2}>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <div className={dropzoneState(dropzone).className}>
                    {dropzoneState(dropzone).label}
                </div>
            </div>

            <br />

            <Button
                variant="contained"
                startIcon={<CloudUpload />}
                onClick={() => importFile(dropzone.acceptedFiles[0])}
                aria-label="Feltöltés"
            >
                <span>Feltöltés</span>
            </Button>
        </Paper>
    );
}

export default Import;
