import { AUTH, LOGOUT } from '../constants/action/auth';

const auth = (state, action) => {
    state = state || [];
    
    switch (action.type) {
        case AUTH:
            localStorage.setItem('profile', JSON.stringify({ ...action?.data }));
            return { ...state, authData: action?.data };
        case LOGOUT:
            localStorage.removeItem('profile');
            return { ...state, authData: null };
        default:
            return state || [];
    }
}

export default auth;
