import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(4) + ' !important',
        borderRadius: theme.spacing(2),
        
        '@media print': {
            boxShadow: 'none !important',
        },
    },
    
    title: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '2em !important',
        },
    },
    
    button: {
        marginTop: theme.spacing(2) + '!important',
        
        [theme.breakpoints.up('md')]: {
            marginLeft: theme.spacing(1) + ' !important',
        },
        
        '& > span:first-of-type': {
            marginLeft: 4,
            
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
    },
    
    dates: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        
        '@media print': {
            display: 'none !important',
        },
    },
    
    card: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            flexDirection: 'column',
        },
    },
    
    section: {
        borderRadius: '20px',
        margin: '10px',
        flex: 1,
    },

    loadingPaper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '15px',
        height: '39vh',
    },
    
    gridContainer: {
        '@media print': {
            marginTop: theme.spacing(-2),
        },
    },
    
    field: {
        marginBottom: theme.spacing(2) + ' !important',
        paddingRight: theme.spacing(2) + ' !important',
    
        '@media print': {
            width: '33.33333% !important',
            maxWidth: '33.33333% !important',
            flexBasis: '33.33333% !important',
            border: '1px solid #ccc',
            margin: '-1px -1px 0 0 !important',
            padding: theme.spacing(1),
            
            '&.megjegyzes': {
                marginTop: theme.spacing(6) + ' !important',
                width: '100% !important',
                maxWidth: '100% !important',
                flexBasis: '100% !important',
            },
        },
    },
    
    form: {
        position: 'fixed',
        zIndex: -1,
        top: 0,
        left: 0,
        display: 'none',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        height: '100%',
        padding: 20,
        boxSizing: 'border-box',
        background: 'rgba(0, 0, 0, 0.75)',
        overflow: 'auto',
        opacity: 0,
        transition: 'opacity 0.3s ease',
        
        '&.open': {
            zIndex: 1,
            display: 'flex',
            opacity: 1,
        }
    },
}));
