import { makeStyles } from '@mui/styles';

export default makeStyles(theme => ({
    media: {
        height: 0,
        paddingTop: '56.25%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backgroundBlendMode: 'darken',
    },
    
    border: {
        border: 'solid',
    },
    
    fullHeightCard: {
        height: '100%',
    },
    
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '15px',
        height: '100%',
        position: 'relative',
    },
    
    grid: {
        display: 'flex',
    },
    
    details: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: '20px',
    },
    
    title: {
        padding: '0 16px',
    },
    
    cardActions: {
        padding: '0 16px 8px 16px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    
    button: {
        '& span:first-of-type': {
            marginLeft: 4,
    
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    },
}));
