import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import moment from 'moment';

import { Container } from '@mui/material';

import { BASE_URL } from './constants';

import Navbar from './components/Navbar/Navbar';
import Home from './components/Home/Home';
import Estate from './components/Estate/Estate';
import Auth from './components/Auth/Auth';
import Import from './components/Import/Import';

import useStyles from './styles';

const App = () => {
    moment().locale('hu');

    const classes = useStyles();

    return (
        <BrowserRouter basename={BASE_URL}>
            <Container maxWidth="xl" className={classes.container}>
                <Navbar />

                <Routes>
                    <Route path="/" exact element={<Navigate to="/estates" />} />
                    <Route path="/estates" element={<Home />} />
                    <Route path="/estates/search" exact element={<Home />} />
                    <Route path="/estates/:id" element={<Estate />} />
                    <Route path="/auth" exact element={<Auth />} />
                    <Route path="/import" exact element={<Import />} />
                </Routes>
            </Container>
        </BrowserRouter>
    );
}

export default App;
