import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';

import { AppBar, Grid, Typography, Toolbar, Avatar, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';

import { LOGOUT } from '../../constants/action/auth';

import useStyles from './styles';

const Navbar = () => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    
    const userName = user?.result?.name || user?.result?.lastName + ' ' + user?.result?.firstName;

    const setUserData = user => {
        setUser(user);

        if (!user) {
            localStorage.removeItem('profile');
        }
    }
    
    const handleLogout = () => {
        dispatch({ type: LOGOUT });
        setUserData();
        navigate('/');
    }
    
    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem('profile')));

        const token = user?.token;

        if (token) {
            const decodedToken = decode(token);

            if (decodedToken.exp * 1000 < new Date().getTime()) {
                handleLogout();
            } else if (location.pathname === '/auth') {
                navigate('/');
            }
        } else if (location.pathname !== '/auth') {
            navigate('/auth');
        } else {
            setUserData();
        }
    }, [location]);
    
    return (
        <AppBar className={classes.appBar} position="static" color="inherit">
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} md={6}>
                    <div className={classes.brandContainer}>
                        <Typography
                            component={Link}
                            to="/"
                            className={classes.heading}
                            variant="h2"
                            align="left"
                            color="primary"
                        >Nyilvántartás</Typography>
                    </div>
                </Grid>
                
                <Grid item xs={12} md={6}>
                    <Toolbar className={classes.toolbar}>
                        {user ? (
                            <div className={classes.profile}>
                                <Avatar className={classes?.purple} alt={userName} src={user?.result?.imageUrl}>
                                    {user?.result?.firstName?.charAt(0)}
                                </Avatar>
                
                                <Typography className={classes?.userName} variant="h6">{userName}</Typography>
    
                                <Button variant="contained" className={classes.button} color="error" onClick={handleLogout}>
                                    <LogoutIcon />
                                    <span>Kilépés</span>
                                </Button>
                            </div>
                        ) : (
                            <Button component={Link} to="/auth" variant="contained" className={classes.button} color="error">
                                <LoginIcon />
                                <span>Belépés</span>
                            </Button>
                        )}
                    </Toolbar>
                </Grid>
            </Grid>
        </AppBar>
    );
}

export default Navbar;
