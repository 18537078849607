import React from 'react';

import { TextField, Grid, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Input = ({ half, name, label, handleChange, autoFocus, type, handleShowPassword }) => {
    const isPasswordField = ['password', 'confirmPassword'].includes(name);
    
    return (
        <Grid item xs={12} sm={half ? 6 : 12}>
            <TextField
                name={name}
                label={label}
                onChange={handleChange}
                autoFocus={autoFocus}
                type={type}
                variant="outlined"
                fullWidth
                InputProps={isPasswordField ? {
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword}>
                                {type === 'password' ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ),
                } : null}
            />
        </Grid>
    );
}

export default Input;
