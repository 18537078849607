import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Inputs from './Inputs';
import { createEstate, updateEstate } from '../../actions/estates';
import { FIELDS, DATES } from '../../constants/components/EstateForm';

import { Grid, Paper, Typography, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';

import useStyles from './styles';

const EstateForm = ({ currentId, setCurrentId, defaultEstate }) => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const getDefaultEstateData = () => {
        const data = {};
        
        Object.keys(FIELDS).map(key => (data[key] = DATES.includes(key) ? null : ''));
        
        return data;
    }
    
    const defaultEstateData           = getDefaultEstateData();
    const [estateData, setEstateData] = useState(defaultEstate ? {
        ...defaultEstateData, ...defaultEstate
    } : defaultEstateData);
    
    const estate = useSelector(state => {
        return currentId ? state?.estates?.estates?.find(e => e._id === currentId) : null;
    });
    
    useEffect(() => {
        if (estate) {
            setEstateData(estate);
            document.getElementById('EstateForm')?.classList?.add('open');
            document.getElementById('EstateFormOpenButton')?.classList?.add('open');
        }
    }, [estate]);
    
    const handleSubmit = e => {
        e.preventDefault();
        
        if (currentId || defaultEstate?._id) {
            dispatch(updateEstate(currentId || defaultEstate._id, {
                ...estateData,
                lastModified: moment()
            }));
            
            if (defaultEstate?._id) {
                window.location.reload();
            }
        } else {
            dispatch(createEstate(estateData));
            navigate('/');
        }
    
        closeForm();
    }
    
    const handleChange = e => setEstateData({ ...estateData, [e.target.name]: e.target.value });
    
    const clear = () => {
        setCurrentId(0);
        setEstateData(defaultEstateData);
    }
    
    const closeForm = () => {
        if (!defaultEstate?._id) {
            clear();
        }
        
        document.getElementById('EstateForm')?.classList?.remove('open');
        document.getElementById('EstateFormOpenButton')?.classList?.remove('open');
    }
    
    return (
        <Grid container maxWidth="xl" spacing={6}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <form autoComplete="off" noValidate className={classes.form} onSubmit={handleSubmit}>
                        <Button
                            className={classes.close}
                            color="error"
                            size="large"
                            title="Bezárás"
                            onClick={closeForm}
                        >
                            <CloseIcon />
                        </Button>
                        
                        <Typography variant="h2" className={classes.heading} color="primary">
                            {estate || defaultEstateData?._id ? 'Cím szerkesztése' : 'Új cím hozzáadása'}
                        </Typography>
            
                        <Inputs estateData={estateData} handleChange={handleChange} />
            
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            size="large"
                            title="Mentés"
                            className={classes.button}
                        >
                            <SaveIcon />
                            <span>Mentés</span>
                        </Button>
            
                        <Button
                            id="clearEstateForm"
                            type="button"
                            variant="contained"
                            color="error"
                            size="large"
                            title="Bezárás"
                            className={classes.button}
                            onClick={closeForm}
                        >
                            <ClearIcon />
                            <span>Bezárás</span>
                        </Button>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default EstateForm;
