import {
    FETCH_ALL,
    FETCH_BY_SEARCH,
    FETCH_ONE,
    CREATE,
    UPDATE,
    DELETE,
    START_LOADING,
    END_LOADING,
    IMPORT, FETCH_FIELD_NAMES,
} from '../constants/action/estate';

const estates = (state, action) => {
    state = state || {
        isLoading: true,
        estate: {},
        estates: [],
        imported: null,
        fieldNames: [],
        currentPage: 1,
        numberOfPages: 1,
    };
    
    switch (action.type) {
        case START_LOADING:
            return {
                ...state,
                isLoading: true,
            };
            
        case END_LOADING:
            return {
                ...state,
                isLoading: false,
            };
            
        case FETCH_ALL:
        case FETCH_BY_SEARCH:
            return {
                ...state,
                estates: action?.payload?.estates || [],
                currentPage: action?.payload?.currentPage || 1,
                numberOfPages: action?.payload?.numberOfPages || 1,
            };
    
        case FETCH_ONE:
            return {
                ...state,
                estate: action?.payload || [],
            };
            
        case CREATE:
            return {
                ...state,
                estates: [ ...state, action?.payload ],
            };
    
        case DELETE:
            return {
                ...state,
                estates: state.estates.filter(estate => estate._id !== action?.payload),
            };
            
        case UPDATE:
            return {
                ...state,
                estates: state.estates.map(estate => estate._id === action?.payload?._id ? action?.payload : estate),
            };

        case IMPORT:
            return {
                ...state,
                imported: action?.payload,
            };

        case FETCH_FIELD_NAMES:
            return {
                ...state,
                fieldNames: action?.payload,
            };
            
        default:
            return state;
    }
}

export default estates;
