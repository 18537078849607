import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Input from './Input';
import { USER } from "../../constants/components/Auth";
import { signup, signin } from '../../actions/auth';

import {
    Avatar,
    Button,
    Paper,
    Grid,
    Typography,
    Container
} from '@mui/material';

import { LockOutlined, Save, Login/*, Cached*/ } from '@mui/icons-material';

import useStyles from "./styles";

const Auth = () => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [showPassword, setShowPassword] = useState(false);
    const [isSignup/*, setIsSignup*/]         = useState(false);
    const [userData, setUserData]         = useState(USER);
    
    const handleShowPassword = () => setShowPassword(prevShowPassword => !prevShowPassword);
    //const handleSwitchMode   = () => setIsSignup(prevSignup => !prevSignup);
    
    const handleSubmit = e => {
        e.preventDefault();
        
        if (isSignup) {
            return dispatch(signup(userData, navigate));
        }
    
        dispatch(signin(userData, navigate));
    }
    
    const handleChange = e => setUserData({ ...userData, [e.target.name]: e.target.value });
    
    const emailInput = focus => (
        <Input
            name="email"
            label="E-mail cím"
            handleChange={handleChange}
            type="email"
            autoFocus={focus}
        />
    );
    
    return (
        <Container component="main" maxWidth="xs">
            <Paper className={classes.paper} elevation={3}>
                <Avatar className={classes.avatar}>
                    <LockOutlined />
                </Avatar>
                
                <Typography variant="h5">{isSignup ? 'Regisztráció' : 'Bejelentkezés'}</Typography>
                
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {
                            isSignup ? (
                                <>
                                    <Input
                                        name="lastName"
                                        label="Vezetéknév"
                                        handleChange={handleChange}
                                        half
                                        autoFocus
                                    />
    
                                    <Input
                                        name="firstName"
                                        label="Keresztnév"
                                        handleChange={handleChange}
                                        half
                                    />
    
                                    {emailInput()}
                                </>
                             ) : emailInput(true)
                        }
                        
                        <Input
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Jelszó"
                            handleChange={handleChange}
                            handleShowPassword={handleShowPassword}
                        />
    
                        {
                            isSignup && (
                                 <Input
                                     name="confirmPassword"
                                     type={showPassword ? 'text' : 'password'}
                                     label="Jelszó újra"
                                     handleChange={handleChange}
                                     handleShowPassword={handleShowPassword}
                                 />
                             )
                        }
                        
                        <Button
                            className={`${classes.button} ${classes.submit}`}
                            type="submit"
                            variant="contained"
                            color="warning"
                            fullWidth
                        >
                            {isSignup ? (
                                <>
                                    <Save />
                                    <span>Regisztrálás</span>
                                </>
                            ) : (
                                <>
                                    <Login />
                                    <span>Belépés</span>
                                </>
                            )}
                        </Button>
                        
                        {/*<Grid container justifyContent="flex-end">
                            <Grid item>
                                <Button className={classes.button} onClick={handleSwitchMode}>
                                    <Cached />
                                    <span>{isSignup ? 'Bejelentkezés' : 'Regisztráció'}</span>
                                </Button>
                            </Grid>
                        </Grid>*/}
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
}

export default Auth;
