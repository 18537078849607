import React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import 'moment/locale/hu';

import { useDispatch } from "react-redux";

import {
    Card,
    CardActions,
    CardContent,
    Button,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ViewIcon  from '@mui/icons-material/Visibility';
import EditIcon  from '@mui/icons-material/Edit';
import DeleteIcon  from '@mui/icons-material/Delete';

import { deleteEstate } from "../../../actions/estates";

import useStyles from "./styles";

const Estate = ({ fieldNames, estate, setCurrentId, handleDeleteOpen }) => {
    const classes  = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme    = useTheme();
    
    const buttonSize = useMediaQuery(theme.breakpoints.down('sm')) ? 'large' : 'small';
    
    const handleOpenEstate = () => navigate(`/estates/${estate._id}`);
    
    const handleDeleteEstate = () => {
        dispatch(deleteEstate(estate._id));
        document.getElementById('clearEstateForm').click();
    }
    
    return (
        <Card className={classes.card}>
            <div className={classes.details}>
                <Typography variant="body2">Utoljára módosítva: {moment(estate.lastModified).fromNow()}</Typography>
                <Typography variant="body2">Létrehozva: {moment(estate.createdAt).fromNow()}</Typography>
            </div>
            
            <CardContent onClick={handleOpenEstate} style={{ cursor: 'pointer' }}>
                <Typography variant="h6" textAlign="center">{estate[fieldNames[0]]}</Typography>
            </CardContent>
            
            <CardActions className={classes.cardActions}>
                <Button
                    className={classes.button}
                    size={buttonSize}
                    color="success"
                    title='Részletek'
                    onClick={handleOpenEstate}
                >
                    <ViewIcon fontSize={buttonSize} />
                    <span>Részletek</span>
                </Button>
                
                {/*<Button
                    className={classes.button}
                    size={buttonSize}
                    color="warning"
                    title="Szerkesztés"
                    onClick={() => setCurrentId(estate._id)}
                >
                    <EditIcon fontSize={buttonSize} />
                    <span>Szerkesztés</span>
                </Button>*/}
                
                <Button
                    className={classes.button}
                    size={buttonSize}
                    color="error"
                    title="Törlés"
                    onClick={() => handleDeleteOpen(handleDeleteEstate)}
                >
                    <DeleteIcon fontSize={buttonSize} />
                    <span>Törlés</span>
                </Button>
            </CardActions>
        </Card>
    );
}

export default Estate;
