export const FIELDS = {
    tarsashazLakasszovetkezet: 'Társasház-Lakásszövetkezet',
    kezelesKezdete: 'Kezelés kezdete',
    kezelo: 'Kezelő',
    megbizasiSzerzodes: 'Megbízási szerződés',
    dontesiHataskor: 'Döntési hatáskör',
    helyrajziSzam: 'Helyrajzi szám',
    alapitoOkirat: 'Alapító Okirat',
    szmsz: 'SZMSZ',
    adoszam: 'Adószám',
    ev: 'ÉV',
    evDatum: 'ÉV dátum',
    vv: 'VV',
    vvDatum: 'VV dátum',
    tv: 'TV',
    tvDatum: 'TV dátum',
    biztositas: 'Biztosítás',
    kotvenyszam: 'Kötvényszám',
    dijfizetesiGyakorisag: 'Díjfizetési gyakoriság',
    biztositasiEvfordulo: 'Biztosítási évforduló',
    biztositasKezelo: 'Bizt.kezelő',
    alberletekSzama: 'Albérletek száma',
    ossztulajdoniHanyad: 'Össz. tulajdoni hányad',
    osszM2: 'Össz. M2',
    kktgM2: 'KKtg /m2',
    kozosKoltseg: 'Közös költség',
    kozosKoltsegUzlet: 'Közös költség (üzlet)',
    kozosKoltsegTarolo: 'Közös költség (garázs vagy tároló)',
    felujitasiAlap:  'Felújítási alap',
    vizoraKgt: 'Vízóra ktg-e',
    vizoraNelkuliLakasSzama: 'Nem vízórás lakás száma',
    kukakSzama: 'Kukák száma',
    stragokSzama: 'Stragok száma',
    foelzaroHelye: 'Főelzáró helye',
    kertiCsap: 'Kerti csap',
    kulcsok: 'Kulcsok',
    szvbTagok: 'SZVB tagok',
    onkormanyzatiTulajdon: 'Önkorm. Tulajdon',
    kkDijOsszesen: 'KK díj összesen',
    kkDijAlberlet: 'KK díj / albetét',
    a3Plakat: 'A/3 plakát',
    lifKezelo: 'Lift kezelő',
    takaritoNeve: 'Takarító név',
    takaritasiDij: 'Takarítás díj',
    kert: 'Kert van-e',
    kertiMunkaDij: 'Kerti munka díj',
    ltpFundamenta: 'LTP / Fundamenta',
    szerzodesSzam: 'Szerződés szám',
    szerzodesKelte: 'Szerződés kelte',
    futamido: 'Futamidő',
    hitelSzerzodes: 'Hitel szerződés',
    hitelLejarat: 'Hitel lejárat',
    bankszamlaszam: 'Bankszámla szám',
    villanyoraGyariSzam: 'Villanyóra gyári szám',
    villanyoraFelhAzon: 'Villanyóra felh. azon.',
    vizoraGyariSzam: 'Vízóra gyári szám',
    vizoraFelhAzon: 'Vízóra felh. azon.',
    megjegyzes: 'Megjegyzés',
}

export const DATES   = [
    'kezelesKezdete',
    'biztositasiEvfordulo',
];

export const NUMBERS = [
    'alberletekSzama',
    'kkDijOsszesen',
];
