import * as api from '../api/auth';

import { AUTH } from '../constants/action/auth';

import { isObjectEmpty, isString } from '../helpers/index';

export const signup = (userData, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signup(userData);
        
        dispatch({
            type: AUTH,
            data: data || []
        });
        
        navigate('/');
    } catch (err) {
        console.log(err);
    }
}

export const signin = (userData, navigate) => async (dispatch) => {
    try {
        const { data } = await api.signin(userData);

        if (isObjectEmpty(data)) {
            alert('Ez a felhasználó nem létezik!');
        }
        
        dispatch({
            type: AUTH,
            data: data || []
        });
        
        navigate('/');
    } catch (err) {
        if (!isObjectEmpty(err) && isString(err?.message)) {
            return alert(err?.message);
        }

        console.log(err);
    }
}
